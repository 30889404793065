import { http } from './config'
import authHeader from './auth-header';

export default {

    lista_sinistralidade: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/sinistralidade-dash/lista/', {
        params: params,
        headers: authHeader()   
        })  
        },

        lista_sinistralidade_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

            const params= {
                'data-inicial': dataI,
                'data-final': dataF,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
                'produto': produto,
            }
    
            return http.get('/planilha-dash/listas-xls', {
                params: params,
                responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
    
              });

              
            },

            
}
